<template>
 <div class="mm-top-navbar">
	<div class="mm-navbar-custom">
		<nav class="navbar navbar-expand-lg navbar-light p-0">
			<div class="mm-navbar-logo d-flex align-items-center justify-content-between" @click="miniSidebar"> <i class="ri-menu-line wrapper-menu"></i>
				<router-link :to="{name: 'layout.dashboard'}" class="header-logo"> <img :src="sidelogo" class="img-fluid rounded-normal" alt="logo"> </router-link>
			</div>
			<div class="mm-search-bar device-search m-auto">
			</div>
			<div class="d-flex align-items-center">
				<ModeSwitch />
				<b-navbar-toggle target="nav-collapse" class="mr-2 text-primary"> <i class="ri-menu-3-line"></i> </b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<ul class="navbar-nav ml-auto navbar-list align-items-center">

						<Fullscreen class="nav-item nav-icon dropdown full-screen" extraclass="nav-item nav-icon dropdown" />

						<li class="nav-item nav-icon dropdown mr-4" v-nav-toggle>
							<a href="javascript:void(0)" class="search-toggle dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<svg class="svg-icon feather feather-bell text-primary" id="mm-bell-2" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
									<path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
									<path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
								</svg> 
								<span v-if="isNotif" class="bg-primary dots"></span> 
							</a>
							<div class="mm-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
								<div class="card shadow-none m-0 border-0">
									<div class="card-body p-0 ">
										<div class="cust-title p-3">
											<h5 class="mb-0">Notifikasi</h5> </div>
											<div class="my-2" v-for="item in notifList" :key="item.data">
												<a :href="`/spt/${item.id}/sppd`" class="mm-sub-card">
													<div class="media align-items-center" >
														<div class="media-body ml-3">
															<h6 class="mb-0"> <strong> No. SPT: {{ item.no_spt }}</strong></h6>
															<p class="mb-0"><b-badge pill variant="warning">{{ item.telat }}</b-badge></p>
														</div>
													</div>
												</a>
											</div>
											<a class="d-flex justify-content-center p-2 card-footer" href="javascript:void(0)" @click="viewAllNotif" role="button">Lihat Semua</a> 
										</div>
								</div>
							</div>
						</li>
						<li class="my-2 d-none d-sm-block">{{ user.fullName }}</li>
						<!-- <h6 class="mb-0 text-primary">{{ user.fullName }}</h6>  -->
						<li class="nav-item nav-icon dropdown" v-nav-toggle>
							<a class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img :src="endpointImg + user.pathFoto" class="img-fluid avatar-rounded" alt="user"> </a>
							<ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
								<li class="dropdown-item d-flex svg-icon">
									<svg class="svg-icon mr-0 text-primary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
									<router-link :to="{name: 'user-profile'}">Profil Saya</router-link>
								</li>
								<li class="dropdown-item  d-flex svg-icon border-top">
									<svg class="svg-icon mr-0 text-primary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /> </svg>
									<a @click="logout">Logout</a>
								</li>
							</ul>
						</li>
					</ul>
				</b-collapse>
			</div>
		</nav>
	</div>
</div>
</template>
<script>
import {core} from '@/config/pluginInit'
import Fullscreen from '../../../fullscreen/Fullscreen'
import { mapGetters } from 'vuex'


import { getDataService } from '../../../../store/modules/crudservices'
import { url_img } from '../../../../config/config'

export default {
	name:"HeaderStyle1",
	data(){
		return{
			endpointImg: url_img,
			isNotif: false,
			notifCount: 0,
			notifList: []
		}
	},
	components: { Fullscreen },
    computed : {
			...mapGetters({
				logo:'darklogo',
				sidelogo:'logo',
				user:'StateUser',
			}),
			isLoggedIn : function(){ return this.$store.getters.isAuthenticated}
    },
	methods: {
		miniSidebar(){
			core.triggerSet()
		},
		viewAllNotif(){
			this.$router.push('/notif')
		},
		getNotif(){
			const url = '/notif'
      getDataService(url).then(response => {
        this.isNotif = response.data.isNotif
				this.notifList = response.data.data
      })
		},
		async logout (){
			await this.$store.dispatch('LogOut')
			this.$router.push('/login')
		}
	},
  mounted() {
    this.getNotif()
  }
}
</script>
