<template>
<div class="mm-sidebar  sidebar-default ">
	<div class="mm-sidebar-logo d-flex align-items-center justify-content-between">
		<router-link :to="{name: 'layout.dashboard'}" class="header-logo"> <img :src="sidelogo" class="ml-3 img-fluid rounded-normal" alt="e-SPPD" style="height: 60px;"> </router-link>
		<div class="side-menu-bt-sidebar" @click="miniSidebar"> <i class="las la-bars wrapper-menu"></i> </div>
	</div>
	<div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
		<nav class="mm-sidebar-menu">
			<ul id="mm-sidebar-toggle" class="side-menu">
				<li :class="checkActive('layout.dashboard') ? 'active' : ''" v-if="perms.includes('dashboard-view')">
					<router-link :to="{ name: 'layout.dashboard'}" class="svg-icon"> 
						<i class="">
							<svg class="svg-icon" id="mm-dash-1" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
							</svg>
						</i> 
						<span>Dashboard</span> 
					</router-link>
				</li>
        <li :class="checkActive(masterData) ? 'active' : ''" v-if="validasi(['is_admin', 'pegawai-view', 'peran-view', 'anggaran-view', 'pejabat-view'])">
					<a v-b-toggle.MasterData class="svg-icon"> 
            <i class="">
              <svg class="svg-icon" id="mm-dash" width="20" xmlns="http://www.w3.org/2000/svg" fill="none"
								viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </i>
            <span class="ml-2">Master Data</span>
            <i class="las la-angle-right mm-arrow-right arrow-active"></i> 
            <i class="las la-angle-down mm-arrow-right arrow-hover"></i> 
          </a>
          <b-collapse @shown="showCollapse('MasterData')" v-if="validasi(['is_admin','pegawai-view'])" @hide="hideCollapse" tag="ul" id="MasterData" class="submenu" accordion="my-accordion" :visible="checkActive('masterData')">
						<li :class="checkActive('Master.pegawai') ? 'active' : ''" >
							<router-link :to="{ name: 'Master.pegawai'}" class="svg-icon"> 
                <i class="">
									<svg class="svg-icon" id="mm-pegawai-1" xmlns="http://www.w3.org/2000/svg" fill="none"
										viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
									</svg>
                </i> <span>Pegawai</span> 
              </router-link>
						</li>
					</b-collapse>
					<b-collapse @shown="showCollapse('MasterData')" @hide="hideCollapse" tag="ul" v-if="validasi(['is_admin','pejabat-view'])" id="MasterData" class="submenu" accordion="my-accordion" :visible="checkActive('masterData')">
						<li :class="checkActive('Master.pejabat') ? 'active' : ''" >
							<router-link :to="{ name: 'Master.pejabat'}" class="svg-icon"> 
                <i class="">
									<svg class="svg-icon" id="md-pejabat" xmlns="http://www.w3.org/2000/svg"
										fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
											d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
									</svg>
                </i> <span>Pejabat</span> 
              </router-link>
						</li>
					</b-collapse>
          <b-collapse @shown="showCollapse('MasterData')" v-if="validasi(['is_admin','dalam_daerah-view'])" @hide="hideCollapse" tag="ul" id="MasterData" class="submenu" accordion="my-accordion" :visible="checkActive('masterData')">
						<li :class="checkActive('Master.dalamDaerah') ? 'active' : ''" >
							<router-link :to="{ name: 'Master.dalamDaerah'}" class="svg-icon"> 
                <i class="">
									<svg class="svg-icon" id="mm-dalam_daerah-1" xmlns="http://www.w3.org/2000/svg" fill="none"
										viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
									</svg>
                </i> <span>Dalam Daerah</span> 
              </router-link>
						</li>
					</b-collapse>
					<b-collapse @shown="showCollapse('MasterData')" v-if="validasi(['is_admin','anggaran-view'])" @hide="hideCollapse" tag="ul" id="MasterData" class="submenu" accordion="my-accordion" :visible="checkActive('masterData')">
						<li :class="checkActive('Master.anggaran') ? 'active' : ''" >
							<router-link :to="{ name: 'Master.anggaran'}" class="svg-icon"> 
                <i class="">
									<svg class="svg-icon" id="md-anggrn" xmlns="http://www.w3.org/2000/svg"
										fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
											d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
									</svg>
                </i> <span>Anggaran</span> 
              </router-link>
						</li>
					</b-collapse>
				</li>
				<li :class="checkActive('pendukung') ? 'active' : ''" v-if="validasi(['is_admin', 'jenis_transport-view', 'kategori_pengeluaran-view', 'satuan-view'])" >
					<a v-b-toggle.pendukung class="svg-icon"> <i>
						<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" id="mm-pendukung-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
						</svg>
						</i> <span class="ml-2">Data Pendukung</span> <i class="las la-angle-right mm-arrow-right arrow-active"></i> 
						<i class="las la-angle-down mm-arrow-right arrow-hover"></i> 
					</a>
					<b-collapse tag="ul" @shown="showCollapse('pendukung')" v-if="validasi(['is_admin', 'kategori_pengeluaran-view'])" @hide="hideCollapse" id="pendukung" class="submenu " accordion="my-accordion">
						<li :class="checkActive('Pendukung.KategoriPengeluaranList') ? 'active' : ''">
							<router-link :to="{ name: 'Pendukung.KategoriPengeluaranList'}" class="svg-icon"> <i class="">
								<svg class="svg-icon" id="mm-kate-1" xmlns="http://www.w3.org/2000/svg" fill="none"
									viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
								</svg>
								</i> <span>Kategori Pengeluaran</span>
							</router-link>
						</li>
					</b-collapse>
					<b-collapse tag="ul" @shown="showCollapse('pendukung')" v-if="validasi(['is_admin', 'jenis_transport-view'])" @hide="hideCollapse" id="pendukung" class="submenu " accordion="my-accordion">
						<li :class="checkActive('Pendukung.JenisTransport') ? 'active' : ''">
							<router-link :to="{ name: 'Pendukung.JenisTransport'}" class="svg-icon"> <i class="">
								<svg class="svg-icon" id="mm-jentar-1" xmlns="http://www.w3.org/2000/svg" fill="none"
									viewBox="0 0 24 24" stroke="currentColor">
									<path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
								</svg>
								</i> <span>Jenis Transport</span>
							</router-link>
						</li>
					</b-collapse>
					<b-collapse tag="ul" @shown="showCollapse('pendukung')" v-if="validasi(['is_admin', 'satuan-view'])" @hide="hideCollapse" id="pendukung" class="submenu " accordion="my-accordion">
						<li :class="checkActive('Pendukung.satuan') ? 'active' : ''">
							<router-link :to="{ name: 'Pendukung.satuan'}" class="svg-icon"> <i class="">
								<svg class="svg-icon" id="mm-satuan-1-3" xmlns="http://www.w3.org/2000/svg"
									fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
								</svg>
								</i> <span class="">Satuan Pengeluaran</span> 
							</router-link>
						</li>
					</b-collapse>
				</li>
				<li :class="checkActive(konpig) ? 'active' : ''" v-if="validasi(['is_admin','user-view', 'peran-view'])">
					<a v-b-toggle.konpig class="svg-icon"> <i>
						<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" id="mm-konpig-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
						</svg>
						</i> <span class="ml-2">Pengaturan Aplikasi</span> <i class="las la-angle-right mm-arrow-right arrow-active"></i> 
						<i class="las la-angle-down mm-arrow-right arrow-hover"></i> 
					</a>
					<b-collapse tag="ul" @shown="showCollapse('konpig')" v-if="validasi(['is_admin', 'user-view'])" @hide="hideCollapse" id="konpig" :visible="checkActive(konpig)" class="submenu " accordion="my-accordion">
						<li :class="checkActive('Config.user') ? 'active' : ''">
							<router-link :to="{ name: 'Config.user'}" class="svg-icon"> <i class="">
								<svg class="svg-icon" id="mm-user-1" xmlns="http://www.w3.org/2000/svg" fill="none"
									viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
											d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
								</svg>
								</i> <span>User</span>
							</router-link>
						</li>
					</b-collapse>
					<b-collapse tag="ul" @shown="showCollapse('konpig')" v-if="validasi(['is_admin', 'peran-view'])" @hide="hideCollapse" id="konpig" :visible="checkActive(konpig)" class="submenu " accordion="my-accordion">
						<li :class="checkActive('Config.peran') ? 'active' : ''">
							<router-link :to="{ name: 'Config.peran'}" class="svg-icon"> <i class="">
								<svg class="svg-icon" id="mm-user-1-3" xmlns="http://www.w3.org/2000/svg"
									fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
								</svg>
								</i> <span class="">Peran</span> 
							</router-link>
						</li>
					</b-collapse>
				</li>
				<hr class="my-2" />
				<li :class="checkActive(SPT) ? 'active' : ''" v-if="validasi(['is_admin','spt-view'])">
					<router-link :to="{ name: 'SPT.data'}" class="svg-icon"> 
						<i class="">
							<svg class="svg-icon" id="spt" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
							</svg>
						</i> 
						<span>SPT</span> 
					</router-link>
				</li>
				<li :class="checkActive(SPTLog) ? 'active' : ''" v-if="validasi(['is_admin','spt-log'])">
					<router-link :to="{ name: 'SPT.log'}" class="svg-icon"> 
						<i class="">
							<svg class="svg-icon" id="spt" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
							</svg>
						</i> 
						<span>SPT Log</span> 
					</router-link>
				</li>
				<hr class="my-2" />
				<li :class="checkActive(report) ? 'active' : ''" v-if="validasi(['is_admin','laporan-anggaran', 'laporan-tahunan', 'laporan-pegawai'])">
					<a v-b-toggle.report class=" svg-icon"> <i>
						<svg class="svg-icon" id="mm-chart-1" width="20" xmlns="http://www.w3.org/2000/svg"
							fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
								d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
						</svg>
						</i> <span class="ml-2">Laporan</span> <i class="las la-angle-right mm-arrow-right arrow-active"></i> 
						<i class="las la-angle-down mm-arrow-right arrow-hover"></i> 
					</a>
					<b-collapse tag="ul" @shown="showCollapse('report')" v-if="validasi(['is_admin', 'laporan-tahunan'])" @hide="hideCollapse" id="report" :visible="checkActive(report)" class="submenu " accordion="my-accordion">
						<li :class="checkActive('report.Rekap') ? 'active' : ''">
							<router-link :to="{ name: 'report.Rekap'}" class="svg-icon"> <i class="">
								<svg xmlns="http://www.w3.org/2000/svg" id="mm-rekap-1-2" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
								</svg>
								</i> <span class="">Rekap Tahunan</span> </router-link>
						</li>
					</b-collapse>
					<b-collapse tag="ul" @shown="showCollapse('report')" v-if="validasi(['is_admin', 'laporan-pegawai'])" @hide="hideCollapse" id="report" :visible="checkActive(report)" class="submenu " accordion="my-accordion">
						<li :class="checkActive('report.Pegawai') ? 'active' : ''">
							<router-link :to="{ name: 'report.Pegawai'}" class="svg-icon"> <i class="">
								<svg xmlns="http://www.w3.org/2000/svg" id="mm-rPegawai-1-2" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
								</svg>
								</i> <span class="">Pegawai</span> </router-link>
						</li>
					</b-collapse>
				</li>
			</ul>
		</nav>
		<div class="pt-5 pb-2"></div>
	</div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import { cekAkses } from '../../../../config/authenticate'
export default {
  name:"SidebarStyle",
  props:{
    items: Array,
  },
  data () {
    return{
      homeurl:'',
      SPT:['SPT.data'],
      SPTLog:['SPT.log'],
      masterData:['Master.pegawai', 'Master.anggaran', 'Master.pejabat', 'Master.pejabat', 'Master.dalamDaerah'],
      report:['report.Rekap', 'report.Pegawai', 'report.Anggaran'],
      profile:['user-profile'],
      dashboards:['layout.dashboard'],
      auth:['auth.login','auth.recover-password'],
			konpig: ['Config.user', 'Config.peran'],
			pendukung: ['Pendukung.KategoriPengeluaranList', 'Pendukung.JenisTransport', 'Pendukung.satuan']
    }
  },
  mounted () {
    core.SmoothScrollbar()
  },
  destroyed () {
    core.SmoothScrollbar()
  },
  computed : {
    ...mapGetters({
      logo:'darklogo',
      sidelogo:'logo',
      perms:'StatePerms'
    })
  },
  methods: {
    checkActive(route) {
      if (this.$route.name == route) {
        return true;
      }
      if (route.includes(this.$route.name)) {
        return true
      }
    },
		validasi(perms){
			return cekAkses(perms)
		},
    miniSidebar(){
      core.triggerSet()
    },
    showCollapse(collapseId) {
      document.getElementById(collapseId).closest('li').classList.add('active')
    },
    hideCollapse() {
      const list = document.querySelector('.mm-sidebar-menu').querySelectorAll('.submenu')
      Array.from(list, elem => {
        if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
          elem.closest('.active').classList.remove('active')
        }
      })
    }
  },
}
</script>